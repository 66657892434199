import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IUser} from "../../interfaces/IUser";
import {BsPeople} from "react-icons/bs";
import useApiUpdateUser from "./api-hooks/useApiUpdateUser";

const useCustomCellRenderer = (row: IUser, theme: any) => {
    const updateMutation = useApiUpdateUser();

    const handleUserToggle = async (row: IUser) => {
        try {
            const payload = {
                is_active: row.is_active ? 0 : 1
            };

            await updateMutation.mutateAsync({id: row.id, data: payload});
        } catch (error) {
            console.error('Failed to update user', error);
        }
    };

    const getIconsForCustomField = (row: IUser, theme: any) => {
        const iconColor = theme.palette.primary.main;

        return (
            <BsPeople
                onClick={() => handleUserToggle(row)}
                color={row.is_active ? iconColor : 'red'}
                title={row.is_active ? 'active' : 'not active'}
            />
        );
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    <span>{icons}</span>
                </Box>
            </IconContext.Provider>
        );
    };

    return { renderIcons };
};

export default useCustomCellRenderer;
