import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import useApi from "../../../utils/request hooks/useApi";
import {AxiosError} from "axios";



const useApiDeleteUser = (pk: number | undefined): UseMutationResult<void, AxiosError, {
    pk?: number
}, unknown> => {
    const queryClient = useQueryClient();

    const {DELETE} = useApi()

    async function doDelete({pk}: {
        companyToken?: string,
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/backoffice/user/${pk}/`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, AxiosError, { pk?: number }>(
        {
            mutationFn: () => doDelete({
                pk: pk,
            }),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetUser', pk]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetUsers"]});
            },
        })
}


export default useApiDeleteUser;
