import useApi from "../../../../utils/request hooks/useApi";
import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";

interface CustomResponse extends AxiosResponse {
    status: string & number,
    message: string,
    reset_token: string,
}


const usePostPasswordResetVerify = () => {
    const {post} = useApi()

    async function doPost(data: { verification_code: string }): Promise<CustomResponse> {
        const response = await post('/user/password/reset/verify', data);
        return response.data[0];
    }

    return useMutation({
        mutationFn: doPost,
    });
};

export default usePostPasswordResetVerify;
