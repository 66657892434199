import React, {ReactNode} from 'react';
import {ProvideAuth} from "../utils/contexts/AuthContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {THEMES} from "../utils/themes/globalTheme";
import {ThemeProvider} from "@mui/material";
import {ResponseMessagesProvider} from "../utils/contexts/ResponseMessageContext";

type Props = {
    children: ReactNode;
}

const queryClient = new QueryClient()
const MainProviders = ({children}: Props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ProvideAuth>
                <ResponseMessagesProvider>
                    <ThemeProvider theme={THEMES.themeGlobal}>
                        {children}
                    </ThemeProvider>
                </ResponseMessagesProvider>
            </ProvideAuth>
        </QueryClientProvider>
    );
};

export default MainProviders;
