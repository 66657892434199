import React from 'react';
import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import HomeScreen from "../components/Home/HomeScreen";
import LoginScreen from "../components/Login/LoginScreen";
import Layout from "../ui/Layout/Layout";
import UserScreen from "../components/User/UserScreen";
import UserAuthorize from "../components/User/UserAuthorize/UserAuthorize";
import PasswordReset from "../components/User/PasswordReset/PasswordReset";
import PasswordVerificationToken from "../components/User/PasswordReset/PasswordVerificationToken";

const Navigation = () => {

    return (
        <Routes>
            <Route path="/api/user/register/authorize/:token" element={<UserAuthorize/>}/>
            <Route path="/user/password/reset" element={<PasswordReset/>}/>
            <Route path="/app/password-verification-token/:token" element={<PasswordVerificationToken/>}/>
            <Route path="/" element={<Layout/>}>
                <Route path="/*" element={
                    <LoginScreen/>
                }/>
                <Route index path="/login" element={
                    <LoginScreen/>
                }/>
                <Route index path="/login" element={
                    <LoginScreen/>
                }/>
                <Route path="/home" element={
                    <ProtectedRoute>
                        <HomeScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/user" element={
                    <ProtectedRoute>
                        <UserScreen/>
                    </ProtectedRoute>
                }/>
            </Route>
        </Routes>
    );
};

export default Navigation;
