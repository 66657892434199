import React from 'react';
import LoginForm from "./LoginForm";
import {Box} from '@mui/material';

const LoginScreen = () => {
    // const {data, error} = useGetWhoAmI()
    // const axiosError = error as CustomAxiosError;
    // if (axiosError) {
    //     // console.log(axiosError)
    //     // console.log(axiosError.response.data.message)
    // }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <LoginForm/>
        </Box>
    );
};

export default LoginScreen;