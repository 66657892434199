import useApi from "../../../../utils/request hooks/useApi";
import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";


const usePostPasswordReset = () => {
    const {post} = useApi()

    async function doPost(data: { email: string }): Promise<AxiosResponse> {
        return await post('/user/password/reset', data);
    }

    return useMutation({
        mutationFn: doPost,
    });
};

export default usePostPasswordReset;
