import {Stack} from "@mui/material";
import {useResponseMessagesContext} from "../../utils/contexts/ResponseMessageContext";

const SnackBarStack = () => {
    const {messages} = useResponseMessagesContext()
    return(
        <Stack sx={{backgroundColor:"orange"}}>
            {/*{messages.map(message => message)}*/}
        </Stack>
    )
}

export default SnackBarStack
