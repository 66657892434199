import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';


interface MuiReadOnlyBoxProps {
    label: string | undefined;
    value?: string | number;
    borderColor?: string;
}

const MuiReadOnlyBoxBody: React.FC<MuiReadOnlyBoxProps> = ({
                                                               label,
                                                               value,
                                                               borderColor,
                                                           }) => {
    const theme = useTheme();
    const _borderColor = borderColor ? borderColor : theme.palette.primary.light;


    return (
        <Box
            sx={{
                border: `2px solid ${_borderColor}`,
                borderRadius: 4,
                padding: 1,
                bgcolor: 'white',
                position: 'relative',
            }}
        >
            <Typography
                variant="subtitle2"
                sx={{
                    color: _borderColor,
                    position: 'absolute',
                    top: -20,
                    left: 4,
                    bgcolor: 'transparent',
                    paddingX: 1,
                }}
            >
                {label}
            </Typography>
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    paddingX: 1,
                }}
            >{value}

            </Typography>
        </Box>
    );
};

export default MuiReadOnlyBoxBody;
