import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IUser} from "../../../interfaces/IUser";
import {AxiosError} from "axios";
import useApi from "../../../utils/request hooks/useApi";


const useApiUpdateUser = (): UseMutationResult<IUser, AxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();

    const {putEncoded} = useApi()

    async function doUpdate(pk: number | undefined,
                            data: any): Promise<IUser> {
        try {
            const response = await putEncoded(`/backoffice/user/${pk}`, data);
            return response.data.data as IUser;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IUser, AxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUser', data.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetUsers"]});
        },
    });
};


export default useApiUpdateUser;
