import React, {FC} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UndoIcon from '@mui/icons-material/Undo';



interface AlertDialogProps {
    open: boolean;
    title: string;
    content: string;
    onClose: () => void;
    onConfirm?: () => void;
}

const MuiAlertDialog: FC<AlertDialogProps> = ({open, title, content, onClose, onConfirm}) => {


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle
                style={{textAlign: 'center', padding: '16px', textTransform: 'uppercase'}}>{title}</DialogTitle>
            <DialogContent style={{textAlign: 'center', paddingInline: '16px'}}>{content}</DialogContent>
            <DialogActions style={{justifyContent: 'center', paddingBottom: '16px'}}>
                <UndoIcon onClick={onClose}/>
                {onConfirm &&
                    <DeleteForeverIcon onClick={onConfirm}/>}
            </DialogActions>
        </Dialog>
    );
};

export default MuiAlertDialog;
