import React, {useState} from 'react';
import styled from 'styled-components';
import usePostPasswordReset from "./api-hooks/usePostPasswordReset";
import LabelledLoading from "../../../ui/LabelledLoading";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
`;

const Message = styled.div`
    margin-top: 16px;
`;

const MainMessage = styled.p<{ color: string }>`
    text-align: center;
    margin-top: 16px;
    color: ${(props) => props.color};
`;

const FooterMessage = styled.p`
    text-align: center;
    margin-top: 16px;
`;

const EmailInput = styled.input`
    margin-top: 16px;
    padding: 8px;
    font-size: 16px;

    &:focus {
        border-color: #008c35;
        outline: none; /* Removes the default outline */
    }
`;

const SubmitButton = styled.button`
    margin-top: 16px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    background-color: ${(props) => (props.disabled ? '#ccc' : '#008c35')};
    color: white;
    border: none;
    border-radius: 4px;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const PasswordReset: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('Write here your email address and send it.')
    const [messageColor, setMessageColor] = useState('black')

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setEmail(email);
        setIsValidEmail(validateEmail(email));
    };

    const {mutateAsync: mutation} = usePostPasswordReset();
    const sendEmailAddress = async (email: string) => {
        setIsLoading(true);
        try {
            const response = await mutation({
                email: email,
            });
            if (response) {
                if (response.status === 201) {
                    setMessageColor('green');
                    setMessage('Well done. Please check your MailBox');
                }
                if (response.status === 200 && response.data.message) {
                    setMessageColor('red');
                    setMessage(response.data.message);
                }
            }
        } catch (error) {
            // console.log(error)
        }
        setIsLoading(false);
    }


    const handleSubmit = async () => {
        if (isValidEmail) {
            console.log(`Email submitted: ${email}`);
            await sendEmailAddress(email);
        }
    };


    return (
        <Container>
            <EmailInput
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={handleEmailChange}
            />
            {isLoading ?
                <div style={{marginTop: 16}}>
                    <LabelledLoading/>
                </div>
                :
                <SubmitButton onClick={handleSubmit} disabled={!isValidEmail}>
                    Send
                </SubmitButton>}
            <Message>
                <MainMessage color={messageColor}>
                    {message}
                </MainMessage>
                <FooterMessage>
                    <br/><br/>
                    Magnabosco Guido srl . – Italy <br/>
                    Tel. +39 0444 450404 - VAT IT01267180246
                </FooterMessage>
            </Message>

        </Container>
    );
};

export default PasswordReset;
