import {IUser} from "../../interfaces/IUser";
import useApi from "./useApi";
import {useQuery, UseQueryResult} from "@tanstack/react-query";

const useGetWhoAmI = (): UseQueryResult<IUser> => {
    const {get} = useApi()

    async function whoAmI(): Promise<IUser> {
        const response = await get(`/api/whoami`);
        return response.data.data;
    }

    return useQuery<IUser>({
        queryKey: ["whoAmI"],
        queryFn: whoAmI
    });
};

export default useGetWhoAmI;
