import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import useApi from "../../../utils/request hooks/useApi";
import {AxiosError} from "axios";

const usePostLogout = (): UseMutationResult<void, AxiosError, void> => {
    const {post} = useApi()
    const queryClient = useQueryClient();

    async function doPostLogout(): Promise<void> {
        try {
            const response = await post('/logout');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, AxiosError, void>({
        mutationFn: doPostLogout,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["whoAmI"]});
        },
    });
};

export default usePostLogout;
