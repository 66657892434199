import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import usePostAuthorize from "./api-hooks/usePostAuthorize";
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
`;

const Message = styled.div`
    margin-top: 16px;
`;

const AuthorizedMessage = styled.p`
    text-align: center;
    margin-top: 16px;
`;

const UserAuthorize: React.FC = () => {
    const {token} = useParams<{ token: string }>();
    const [message, setMessage] = useState('Processing authorization....');
    const [isAuthorized, setIsAuthorized] = useState(false);

    const {mutateAsync: mutation} = usePostAuthorize();

    useEffect(() => {
        console.log(`Token received: ${token}`);
        if (token) {
            authorize(token);
        }
    }, [token]);

    const authorize = async (token: string) => {
        try {
            const response = await mutation(token);
            if (response.status.toString() === 'ko') {
                setMessage('Autorizzazione Negata');
            } else {
                setIsAuthorized(true);
                setMessage("Autorizzazione avvenuta con successo, puoi procedere con il login nell'app.");
            }
        } catch (error) {
            console.error(error);
            setMessage('Errore durante l\'autorizzazione. Riprova più tardi.');
        }
    };

    return (
        <Container>
            <div>
                {message}
            </div>
            {isAuthorized && (
                <Message>
                    <AuthorizedMessage>
                        <br/>
                        <br/>
                        Grazie <br/><br/>
                        Magnabosco Guido srl . – Italy <br/>
                        Tel. +39 0444 450404 - VAT IT01267180246
                    </AuthorizedMessage>
                </Message>
            )}
        </Container>
    );
};

export default UserAuthorize;
