import {IUser} from "../../interfaces/IUser";
import {Avatar, Box, CircularProgress, Popover, useTheme} from "@mui/material";
import {useQueryClient} from "@tanstack/react-query";
import React, {useEffect, useMemo, useState} from "react";
import useApiDeleteUser from "./api-hooks/useApiDeleteUser";
import useApiGetUsers from "./api-hooks/useApiGetUsers";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps, getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../ui/MuiReactTable/MuiReactTableStyles";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {Delete, Edit} from "@mui/icons-material";
import {BsPeople} from "react-icons/bs";
import MuiReadOnlyBoxBody from "../../ui/MuiDisplayData/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../ui/MuiReactTable/MRTMuiButton";
import AddIcon from '@mui/icons-material/Add';
import MuiAlertDialog from "../../ui/MuiAlertDialog";
import UserForm from "./UserForm";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import useApiUpdateUser from "./api-hooks/useApiUpdateUser";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {CSVLink} from "react-csv";
import Papa from 'papaparse';
import FileDownloadIcon from '@mui/icons-material/FileDownload'


interface IUserCustomField extends IUser {
    custom_field: JSX.Element;
}

interface Props {
    onSelect?: (obj: IUser | undefined) => void,
    onClose?: () => void;
    alreadySelectedObj?: IUser | undefined,
}


const UserTable = ({onSelect, onClose, alreadySelectedObj}: Props) => {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IUser | undefined>()
    const [rows, setRows] = useState<IUser[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteUser(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;



    const updateMutation = useApiUpdateUser();
    const handleUserToggle = async (row : IUser) => {
        try {
            const payload = {
                is_active: row.is_active ? 0 : 1
            };

            await updateMutation.mutateAsync({id: row.id, data: payload});

        } catch (error) {
        }
    }


    const handleConfirmDelete = async () => {
        try {
            if (onSelect && (alreadySelectedObj?.id === selectedRow?.id)) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {}
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        isLoading,
        error,
        isFetching,
    } = useApiGetUsers();
    if (error) {}


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const CustomCellRenderer: React.FC<{ row: IUser, theme: any }> = ({ row, theme }) => {
        const { renderIcons } = useCustomCellRenderer(row, theme);
        return renderIcons();
    };

    const [objsWithCustomField, setObjsWithCustomField] = useState<IUserCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IUserCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: <CustomCellRenderer row={row} theme={theme} />
        }));
        setObjsWithCustomField(dataWithCustomField);
    }, [rows, theme]);

    const convertArrayOfObjectsToCSV = (data: IUser[]): string => {
        const csv = Papa.unparse(data, {
            delimiter: ';',
        });
        return csv;
    };


    const columns = useMemo<MRT_ColumnDef<IUser>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'email',
                header: 'Email',
                filterFn: 'contains',
            },
            {
                accessorKey: 'name',
                header: 'Name',
                filterFn: 'contains',

            },
            {
                accessorKey: 'surname',
                header: 'Surname',
                filterFn: 'contains',
            },
            // {
            //     accessorKey: 'company',
            //     header: 'Company',
            //     filterFn: 'contains',
            // },
            // {
            //     accessorKey: 'company_role',
            //     header: 'Company Role',
            //     filterFn: 'contains',
            // },
            // {
            //     accessorKey: 'company_sector',
            //     header: 'Company Sector',
            //     filterFn: 'contains',
            // },
            // {
            //     accessorKey: 'phone',
            //     header: 'Phone',
            //     filterFn: 'contains',
            // },

        ],
        []
    );


    const table = useMaterialReactTable<IUser>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: objsWithCustomField ? objsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows ? rows?.length > 25 : false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={"Select"}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />
            ),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={"Edit"}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={true}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={"Delete"}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={true}
            />,
            <MRT_ActionMenuItem
                icon={row.original.is_active ? <ToggleOffIcon color="warning"/> : <ToggleOnIcon color="primary"/>}
                key="toggle"
                label={row.original.is_active ? 'set inactive' : 'set active'}
                onClick={() => {
                    handleUserToggle(row.original)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <BsPeople style={{color: theme.palette.primary.main}}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'Users'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'Add User'}/>
                    {rows &&
                        <CSVLink data={convertArrayOfObjectsToCSV(rows)} filename={'all_rows.csv'} title={'download CSV'}>
                            {<FileDownloadIcon  style={{color: theme.palette.primary.main, fontSize: '2rem', marginTop:8}}/>}
                        </CSVLink>
                    }
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <CircularProgress/> :
            <>
                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="Delete Confirmation"
                    content="Are you sure you want to delete this user?"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <Popover
                    id={'edit_popover'}
                    open={isEditFormOpen}
                    // anchorEl={anchorEl}
                    onClose={() => setIsEditFormOpen(false)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <UserForm objToEdit={selectedRow ? selectedRow : null}
                              onClose={() => setIsEditFormOpen(false)}/>
                </Popover>


            </>}
    </>;

};

export default UserTable;
