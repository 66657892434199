import React, {Fragment} from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../utils/contexts/AuthContext";

type Props = {
    children: React.ReactNode
}

const ProtectedRoute = ({children}: Props) => {
    const {isAuthenticated} = useAuth();

    return (
        <Fragment>
            {isAuthenticated
                ? <Fragment>
                    {children}
                </Fragment>
                : <Navigate to="/login" replace/>
            }
        </Fragment>
    )

};

export default ProtectedRoute;
