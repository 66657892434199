import React from 'react';
import {Box, CircularProgress, Typography, TypographyVariant} from "@mui/material";

type Props = {
    label?: string,
    labelPosition?: "top" | "bottom" | "left" | "right"
    typographyVariant?: TypographyVariant
    progressionSize?: number,
}

const LabelledLoading = ({
                             label = "",
                             progressionSize = 20,
                             typographyVariant = "body1",
                             labelPosition = "right"
                         }: Props) => {
    return (
        <Box
            sx={{
                display: "flex",
                color: "primary.main",
                gap: 1,
                alignItems: "center",
                flexDirection:
                    labelPosition === "right"
                        ? "row"
                        : labelPosition === "left"
                            ? "row-reverse"
                            : labelPosition === "top"
                                ? "column-reverse"
                                : "column"

            }}
        >
            <CircularProgress size={progressionSize}/>
            <Typography variant={typographyVariant}>
                {label}
            </Typography>
        </Box>
    );
};

export default LabelledLoading;
