import {AxiosResponse} from 'axios';
import useCustomAxiosInstance from "./useCustomAxiosInstance";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
console.log("Endpoint: ", ENDPOINT);

const useApi = () => {
    const {customAxiosInstance} = useCustomAxiosInstance();

    const get = async (url: string, params?: any): Promise<AxiosResponse<any>> => {
        try {
            return await customAxiosInstance.get(ENDPOINT + url, {params: {...params}});
        } catch (error) {
            throw error;
        }
    };

    const post = async (url: string, params?: any): Promise<AxiosResponse<any>> => {
        try {
            return await customAxiosInstance.post(ENDPOINT + url, {...params});
        } catch (error) {
            throw error;
        }
    };

    const postEncoded = async (url: string, params?: any): Promise<AxiosResponse<any>> => {
        try {
            return await customAxiosInstance.post(ENDPOINT + url, new URLSearchParams({...params}));
        } catch (error) {
            throw error;
        }
    };

    const put = async (url: string, data?: any): Promise<AxiosResponse<any>> => {
        try {
            return await customAxiosInstance.put(ENDPOINT + url, {...data});
        } catch (error) {
            throw error;
        }
    };

    const putEncoded = async (url: string, params?: any): Promise<AxiosResponse<any>> => {
        try {
            return await customAxiosInstance.put(ENDPOINT + url, new URLSearchParams({...params}));
        } catch (error) {
            throw error;
        }
    };

    const DELETE = async (url: string): Promise<AxiosResponse<any>> => {
        try {
            return await customAxiosInstance.delete(ENDPOINT + url);
        } catch (error) {
            throw error;
        }
    };

    return {
        get,
        post,
        postEncoded,
        put,
        putEncoded,
        DELETE
    };
};

export default useApi;
