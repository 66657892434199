import React, {useState, useEffect} from 'react';
import {TextField} from '@mui/material';
import Box from '@mui/material/Box';
import {useTheme} from "@mui/material/styles";

interface MuiTextFieldProps {
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef?: React.RefObject<HTMLInputElement>;
    borderColor?: string;
    onFocusBorderColor?: string;
    type?: string;
    value?: string | number;
    required?: boolean;
    step?: string;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
}

const MuiInputTextField: React.FC<MuiTextFieldProps> = ({
                                                            label,
                                                            onChange,
                                                            inputRef,
                                                            borderColor,
                                                            onFocusBorderColor,
                                                            type = 'text',
                                                            value,
                                                            required,
                                                            step,
                                                            multiline,
                                                            rows,
                                                            disabled = false,
                                                        }) => {
    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);

    const _borderColor = borderColor ? borderColor : theme.palette.primary.light;
    const _onFocusBorderColor = onFocusBorderColor ? onFocusBorderColor : theme.palette.primary.dark;

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    return (
        <Box>
            <TextField
                type={type}
    label={label}
    onChange={onChange}
    onFocus={() => setIsFocused(true)}
    onBlur={() => setIsFocused(false)}
    inputRef={inputRef}
    fullWidth
    value={value !== undefined && value !== null ? value : ''}
    required={required}
    multiline={multiline}
    rows={rows}
    margin="dense"
    autoComplete="off"
    size="small"
    variant="filled"
    disabled={disabled}
    sx={{
        bgcolor: 'white',
            '& label.Mui-focused': {
            color: _borderColor,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
                borderColor: _borderColor,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: _onFocusBorderColor,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: _borderColor,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: _borderColor,
        },
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: _onFocusBorderColor,
        },
        '& .MuiFilledInput-underline:before': {
            borderBottomColor: _borderColor,
        },
        '&:hover .MuiFilledInput-underline:before': {
            borderBottomColor: _borderColor,
        },
        '& .MuiFilledInput-root.Mui-focused .MuiFilledInput-underline:after': {
            borderBottomColor: _borderColor,
        },
    }}
    />
    </Box>
);
};

export default MuiInputTextField;
