import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IUser} from "../../../interfaces/IUser";
import useApi from "../../../utils/request hooks/useApi";



const useApiGetUsers = (): UseQueryResult<IUser[], AxiosError> => {
    const {get} = useApi()

    async function doGet(): Promise<IUser[]> {
        try {
            const response = await get(`/backoffice/user`);
            return response?.data.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetUsers"],
        queryFn: () => doGet(),
    })
};

export default useApiGetUsers
