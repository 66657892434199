import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IUser} from "../../../interfaces/IUser";
import {AxiosError} from "axios";
import useApi from "../../../utils/request hooks/useApi";


const useApiCreateUser = (): UseMutationResult<IUser, AxiosError, any> => {
    const queryClient = useQueryClient();

    const {post} = useApi()

    async function doCreate({data}: {
        companyToken?: string,
        data: any
    }): Promise<IUser> {
        try {
            const response = await post(`/backoffice/user`, data);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IUser, AxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetUsers"]});
            },
        }
    );
};


export default useApiCreateUser;
