import React, {useEffect, useState} from 'react';
import {useAuth} from "../../utils/contexts/AuthContext";
import NavBar from "./NavBar";
import {Box, CircularProgress, Stack} from "@mui/material";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useGetWhoAmI from "../../utils/request hooks/useGetWhoAmI";
import TopBar from "./TopBar";
import SnackBarStack from "./SnackBarStack";

const Layout = () => {
    const {isAuthenticated, setIsAuthenticated} = useAuth();
    const navigate = useNavigate();
    const [isNavBarOpen, setIsNavBarOpen] = useState<boolean>(false);
    const [pageTitle, setPageTitle] = useState<string>("")
    const location = useLocation();

    const {
        data: dataWhoAmI,
        error: errorWhoAmI,
        isFetching: isFetchingWhoAmI,
        failureCount: failureCountWhoAmI
    } = useGetWhoAmI()
    if (errorWhoAmI) {
        console.log("who am i error", errorWhoAmI)
        // navigate('/login');
    }

    const handleToggleNavBar = () => {
        setIsNavBarOpen(!isNavBarOpen)
    }

    useEffect(() => {
        if (dataWhoAmI?.roles?.includes("ROLE_ADMIN")) {
            setIsAuthenticated(true);
            navigate(location.pathname);
        }
    }, [dataWhoAmI, location.pathname]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated]);

    return (
        <>
            {failureCountWhoAmI < 1 && isFetchingWhoAmI
                ? <CircularProgress/>
                : <>
                    {isAuthenticated &&
                        <NavBar
                            isOpen={isNavBarOpen}
                            handleToggle={handleToggleNavBar}
                            setAppbarTitle={setPageTitle}
                        />
                    }
                    <Stack>
                        {isAuthenticated && <TopBar toggleNavBar={handleToggleNavBar} pageTitle={pageTitle}/>}
                        <Box mt={8}> {/* Adjust the margin top value as needed */}
                            <Outlet/>
                        </Box>
                    </Stack>
                    <SnackBarStack/>
                </>
            }
        </>
    );
};

export default Layout;
