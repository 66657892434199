import {IUser} from "../../interfaces/IUser";
import React, {useEffect, useState} from "react";
import useApiCreateUser from "./api-hooks/useApiCreateUser";
import useApiUpdateUser from "./api-hooks/useApiUpdateUser";
import useApiGetUsers from "./api-hooks/useApiGetUser";
import useApiGetUser from "./api-hooks/useApiGetUser";
import {Box} from "@mui/material";
import MuiInputTextField from "../../ui/MuiInputData/MuiInputTextField";
import UndoIcon from "@mui/icons-material/Undo";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SaveIcon from '@mui/icons-material/Save';


interface Props {
    objToEdit: IUser | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IUser | undefined) => void,
}

const UserForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const [email, setEmail] = useState<string>();
    const [name, setName] = useState<string>();
    const [surname, setSurname] = useState<string>();
    const [company, setCompany] = useState<string>();
    const [companyRole, setCompanyRole] = useState<string>();
    const [companySector, setCompanySector] = useState<string>();
    const [phone, setPhone] = useState<string>();

    const createMutation = useApiCreateUser();
    const updateMutation = useApiUpdateUser();

    const {
        data: detailedObj,
    } = useApiGetUser(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setEmail(detailedObj.email);
            setName(detailedObj.name);
            setSurname(detailedObj.surname);
            setCompany(detailedObj.company);
            setCompanyRole(detailedObj.company_role);
            setCompanySector(detailedObj.company_sector);
            setPhone(detailedObj.phone);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                email: email || '',
                name: name || '',
                surname: surname || '',
                company: company || '',
                company_role: companyRole || '',
                company_sector: companySector || '',
                phone: phone || '',
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (email) {
            setAllRequirementAreSatisfied(true);
        }
    }, [email]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setEmail(e.target.value)}
                    label='email'
                    type="text"
                    value={email || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setSurname(e.target.value)}
                    label='surname'
                    type="text"
                    value={surname || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setCompany(e.target.value)}
                    label='company'
                    type="text"
                    value={company || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setCompanyRole(e.target.value)}
                    label='companyRole'
                    type="text"
                    value={companyRole || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setCompanySector(e.target.value)}
                    label='companySector'
                    type="text"
                    value={companySector || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setPhone(e.target.value)}
                    label='phone'
                    type="text"
                    value={phone || ''}
                    required={false}
                />



                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <UndoIcon onClick={onClose}/>
                    {allRequirementAreSatisfied &&
                    <>
                        {objToEdit ?
                            <SaveAsIcon onClick={handleSubmit}/>
                            :
                            <SaveIcon onClick={handleSubmit}/>}
                    </>
                    }
                </Box>
            </form>
        </Box>
    );
};

export default UserForm;
