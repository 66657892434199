import {ThemeOptions} from '@mui/material/styles';
import {createTheme} from "@mui/material";

export const themeOptionsGlobal: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#008c35',
        },
        background: {
            default: 'rgb(247,250,250)',
        },
    },


    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    "-webkit-font-smoothing": "antialiased",
                    "-moz-osx-font-smoothing": "grayscale",
                    height: "100%",
                    width: "100%",
                },
            },
        },

        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "2px",
                    color: "white",
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    transition: "transform",
                    ":hover": {
                        backgroundColor: "transparent",
                        transform: "scale(1.12)"
                    },
                },
            },
        },

        MuiTextField: {
            defaultProps: {
                variant: "outlined",
            },
        },

        MuiTableCell: {
            styleOverrides: {
                head: ({theme}) => ({
                    height: "4rem",
                    fontSize: "18px",
                    fontWeight: "800",
                    backgroundColor: theme.palette.primary.light,
                    zIndex: 1000,
                }),

                body: {
                    fontSize: "16px",
                    borderRight: "1px solid white",
                    borderBottom: "2px solid white",
                },
            },
        },

        MuiModal: {
            styleOverrides: {
                backdrop: {
                    backgroundColor: "rgba(34,34,34,0.27)"
                },
            },
        },

        MuiListItem: {
            defaultProps: {
                dense: true,
                disableGutters: true,
                disablePadding: true,
            },
        },

        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: "white",
                }
            },
        },

        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "medium",
                },
            },
        },

        MuiDrawer: {
            styleOverrides: {
                paper: ({theme}) => ({
                    borderRight: "transparent",
                    width: "12%",
                    backgroundColor: theme.palette.primary.main,
                }),
            }
        }
    },
};

const themeGlobal = createTheme(themeOptionsGlobal);

export const THEMES = {
    themeGlobal: themeGlobal,
}
