import React, {ReactNode} from "react";
import {BsPeople} from "react-icons/bs";
import {VscHome} from "react-icons/vsc";

interface NavbarEntries {
    name: string,
    to: string,
    icon: ReactNode,
}

export const contentNavbarEntries: readonly NavbarEntries[] = [
    {
        name: "Home",
        to: "/home",
        icon: <VscHome/>
    },
    {
        name: "Utenti",
        to: "/user",
        icon: <BsPeople/>
    },
];

