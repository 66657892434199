import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import styled from 'styled-components';
import usePostPasswordResetVerify from "./api-hooks/usePostPasswordResetVerify";
import LabelledLoading from "../../../ui/LabelledLoading";
import usePostPasswordResetChange from "./api-hooks/usePostPasswordResetChange";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
`;

const SubmitButton = styled.button`
    margin-top: 16px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    background-color: ${(props) => (props.disabled ? '#ccc' : '#008c35')};
    color: white;
    border: none;
    border-radius: 4px;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const PasswordInput = styled.input`
    margin-top: 16px;
    padding: 8px;
    font-size: 16px;

    &:focus {
        border-color: #008c35;
        outline: none; /* Removes the default outline */
    }
`;

const Message = styled.div`
    margin-top: 16px;
`;


const MainMessage = styled.p<{ color: string }>`
    text-align: center;
    margin-top: 16px;
    color: ${(props) => props.color};
`;

const FooterMessage = styled.p`
    text-align: center;
    margin-top: 16px;
`;


const PasswordVerificationToken: React.FC = () => {
    const {token} = useParams<{ token: string }>();
    const [messageColor, setMessageColor] = useState('black')
    const [message, setMessage] = useState('Processing authorization....');
    const [resetToken, setResetToken] = useState<string | undefined>()
    const [newPassword, setNewPassword] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false)

    const {mutateAsync: verifyMutation} = usePostPasswordResetVerify();
    const verify = async (token: string) => {
        try {
            const response = await verifyMutation({verification_code: token});
            console.log(response)
            if (response.status === 'ok') {
                setResetToken(response.reset_token)
            } else {
                setMessageColor('red');
                setResetToken(undefined)
                setMessage("Link expired or not valid")
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validatePassword = (password: string) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\.\\\@$!%#*?&\/])[A-Za-z\d\.\\\@$!%#*?&\/]{8,}$/;
        return re.test(password);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setNewPassword(password);
        setIsNewPasswordValid(validatePassword(password));
    };

    const {mutateAsync: newPasswordMutation} = usePostPasswordResetChange();
    const sendNewPassword = async () => {
        if (newPassword && resetToken && isNewPasswordValid) {
            setIsLoading(true);
            try {
                const response = await newPasswordMutation({
                    new_password: newPassword,
                    reset_token: resetToken,
                });
                if (response) {
                    if (response.status === 'ok') {
                        setMessageColor('green');
                        setMessage('Password Changed! You can log in App now');
                        setIsPasswordChanged(true);
                    } else {
                        setMessageColor('ed');
                        setMessage('Something went wrong');
                    }
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false);
        }

    }


    const handleSubmit = async () => {
        if (isNewPasswordValid) {
            console.log(`Password submitted: ${newPassword}`);
            await sendNewPassword();
        }
    };

    useEffect(() => {
        console.log(`Token received: ${token}`);
        if (token) {
            verify(token);
        }
    }, [token]);

    useEffect(() => {
        setMessage('')
        setMessageColor('black')
        if (!isNewPasswordValid) {
            setMessageColor('red')
            setMessage('Password must have at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character.')
        }
    }, [isNewPasswordValid]);


    return (
        <Container>
            {resetToken && !isPasswordChanged &&
                <>
                    <PasswordInput
                        type="text"
                        placeholder="Enter your new password"
                        value={newPassword}
                        onChange={handlePasswordChange}
                    />
                    {isLoading ?
                        <div style={{marginTop: 16}}>
                            <LabelledLoading/>
                        </div>
                        :
                        <SubmitButton onClick={handleSubmit} disabled={!isNewPasswordValid}>
                            Send
                        </SubmitButton>}
                </>}

            <Message>
                <MainMessage color={messageColor}>
                    {message}
                </MainMessage>
                <FooterMessage>
                    <br/><br/>
                    Magnabosco Guido srl . – Italy <br/>
                    Tel. +39 0444 450404 - VAT IT01267180246
                </FooterMessage>
            </Message>

        </Container>
    );
};

export default PasswordVerificationToken;
