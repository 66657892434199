import React, {ReactNode} from "react";
import { Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ButtonProps as MuiButtonProps} from "@mui/material";
import usePostLogout from "../components/Login/api-hooks/usePostLogout";
import {useAuth} from "../utils/contexts/AuthContext";

interface Props extends MuiButtonProps {
    logoutText?: string,
    logoutIcon: ReactNode,
}

const ButtonLogout = ({logoutText = "Logout", logoutIcon, ...props}: Props) => {
    const navigate = useNavigate();
    const {setIsAuthenticated} = useAuth();


    const {mutateAsync: logout} = usePostLogout()

    const handleLogout = async () => {
        try {
            await logout()
            setIsAuthenticated(false)
            navigate("/login")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Button
            variant={"outlined"}
            endIcon={logoutIcon}
            onClick={handleLogout}
            {...props}
        >
            {logoutText}
        </Button>
    )
}

export default ButtonLogout;
