import React, {createContext, ReactNode, useContext, useState} from 'react';

interface IResponseMessagesContext {
    messages: string[];
    addMessage: (newMessage: string) => void;
    clearMessages: () => void;
}

const ResponseMessagesContext = createContext<IResponseMessagesContext | undefined>(undefined);

export const useResponseMessagesContext = () => {
    const context = useContext(ResponseMessagesContext);
    if (!context) {
        throw new Error('useResponseMessagesContext must be used within a ResponseMessagesProvider');
    }
    return context;
};

interface ResponseMessagesProviderProps {
    children: ReactNode
}

export const ResponseMessagesProvider = ({children}: ResponseMessagesProviderProps) => {
    const [messages, setMessages] = useState<string[]>([]);

    const addMessage = (newMessage: string) => {
        setMessages(prevMessages => [...prevMessages, newMessage]);
    };

    const clearMessages = () => {
        setMessages([]);
    };

    return (
        <ResponseMessagesContext.Provider value={{messages, addMessage, clearMessages}}>
            {children}
        </ResponseMessagesContext.Provider>
    );
};
