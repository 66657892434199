import React from "react";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import {Box} from "@mui/material";
import UserTable from "./UserTable";

const UserScreen = () => {

    return (
        <Box>
            <UserTable/>
        </Box>
    );
};

export default UserScreen;
