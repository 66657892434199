import {Typography} from "@mui/material";
import packageJson from "../../package.json";

const ProjectVersion = () => {

    return (
        <Typography
            sx={{
                color:"rgba(65,65,65,0.84)",
                fontSize:"small",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
            noWrap={true}
            variant={"body2"}
        >
            V. {packageJson.version}
        </Typography>
    )
}


export default ProjectVersion;
