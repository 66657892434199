import useApi from "../../../../utils/request hooks/useApi";
import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";


const usePostAuthorize = () => {
    const {post} = useApi()

    async function doPost(token: string): Promise<AxiosResponse> {
        const response = await post('/api/user/register/authorize/' + token);
        return response.data;
    }

    return useMutation({
        mutationFn: doPost,
    });
};

export default usePostAuthorize;
