import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IUser} from "../../../interfaces/IUser";
import useApi from "../../../utils/request hooks/useApi";



const useApiGetUsers = (pk: number | undefined): UseQueryResult<IUser, AxiosError> => {
    const {get} = useApi()

    async function doGet(pk: number | undefined): Promise<IUser> {
        try {
            const response = await get(`/backoffice/user/${pk}`);
            return response?.data.data[0];
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetUser", pk],
        queryFn: () => doGet(pk),
        enabled: !!pk
    })
};

export default useApiGetUsers
