import React from "react";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";

const HomeScreen = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient()


    return (
        <div>
            {/*<button onClick={handleClickWhoAmI}>*/}
            {/*    WHOAMI*/}
            {/*</button>*/}
            {/*<button onClick={handleClickSignOut}>*/}
            {/*    SIGNOUT*/}
            {/*</button>*/}
        </div>
    );
};

export default HomeScreen;
