import useApi from "../../../utils/request hooks/useApi";
import {useMutation, useQueryClient} from "@tanstack/react-query";

interface ILoginData {
    email: string;
    password: string;
}

interface ILoginResponse {
    refresh_token: string,
    token: string,
}

const usePostLogin = () => {
    const {post} = useApi()
    const queryClient = useQueryClient();

    async function doPostLogin(data: ILoginData): Promise<ILoginResponse> {
        const response = await post('/login', data);
        return response.data;
    }

    return useMutation({
        mutationFn: doPostLogin,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["whoAmI"]})
        }
    });
};

export default usePostLogin;
