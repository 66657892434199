import {AppBar, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {VscMenu, VscSignOut} from "react-icons/vsc";
import ButtonLogout from "../ButtonLogout";
import React from "react";

interface Props{
    toggleNavBar: () => void
    pageTitle:string
}

const TopBar = ({toggleNavBar, pageTitle}:Props) => {

    return(
        <AppBar>
            <Toolbar
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <IconButton onClick={toggleNavBar}>
                    <VscMenu size={24} color={"white"}/>
                </IconButton>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography>{pageTitle}</Typography>
                    <ButtonLogout
                        logoutIcon={<VscSignOut/>}
                        disableElevation={true}
                    />
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;
