import useApi from "../../../../utils/request hooks/useApi";
import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";

interface CustomResponse extends AxiosResponse {
    status: string & number,
    message: string,
    reset_token: string,
}


const usePostPasswordResetChange = () => {
    const {post} = useApi()

    async function doPost(data: { new_password: string, reset_token: string }): Promise<CustomResponse> {
        const response = await post('/user/password/reset/change', data);
        console.log(response.data)
        return response.data;
    }

    return useMutation({
        mutationFn: doPost,
    });
};

export default usePostPasswordResetChange;
