import {useEffect} from 'react';
import axios, {AxiosInstance, AxiosError} from 'axios';
import {useResponseMessagesContext} from "../contexts/ResponseMessageContext";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const useCustomAxiosInstance = (): { customAxiosInstance: AxiosInstance } => {
    const {addMessage} = useResponseMessagesContext()
    const customAxiosInstance = axios.create({
        withCredentials: true,
    });

    useEffect(() => {
        //Function called by interceptor when response status <2xx
        //TODO set success message -> missing structure from backend as of 2024-11-06
        const handleResponse = (response: any) => response;

        //Function called by interceptor when response status >2xx
        //Adds message to context's state from backend's response error msg
        const handleError = (error: AxiosError): Promise<never> => {
            if (error.response) {
                const data = error.response.data;
                const message = data && typeof data === 'object' && 'message' in data
                    ? (data as { message: string }).message
                    : 'An error occurred';
                addMessage(message);
                console.log(message)
            } else {
                addMessage('Network error');
            }
            return Promise.reject(error);
        };

        const interceptor = customAxiosInstance.interceptors.response.use(handleResponse, handleError);

        return () => {
            customAxiosInstance.interceptors.response.eject(interceptor);
        };
    }, [addMessage, customAxiosInstance]);

    return {customAxiosInstance: customAxiosInstance};
};

export default useCustomAxiosInstance;
