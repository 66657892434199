import React, {useEffect, useState} from 'react';
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import {contentNavbarEntries} from "../../utils/content/content-navbar";
import {useNavigate,} from "react-router-dom";
import {IconContext} from "react-icons";
import ProjectVersion from "../ProjectVersion";
import {VscClose} from "react-icons/vsc";

interface Props {
    isOpen?: boolean,
    handleToggle?: () => void,
    setAppbarTitle: (title: string) => void,
}

const NavBar = ({isOpen = false, handleToggle, setAppbarTitle}: Props) => {
    const navigate = useNavigate();

    //Default highlighted page is set here manually
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useEffect(() => {
        setAppbarTitle("Home")
    },[setAppbarTitle])

    const handleNavbarNavigation = (title: string, to: string, i: number,) => {
        setAppbarTitle(title)
        setSelectedIndex(i);

        //if first character different from "/"
        if (to[0] !== "/") {
            window.location.href = to;
        } else {
            navigate(to)
            handleToggle && handleToggle()
        }
    }

    return (
        <Drawer
            variant={"persistent"}
            open={isOpen}
        >
            <IconContext.Provider value={{color: "white", size: "20px"}}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        py: 2.45,
                        borderBottom: "1px solid",
                        borderColor: "primary.dark"
                    }}
                >
                    <IconButton
                        onClick={handleToggle}
                        sx={{marginRight: 2}}
                    >
                        <VscClose size={24}/>
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <List sx={{width: "100%"}}>
                        {contentNavbarEntries.map((entryObj, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemButton
                                        sx={{
                                            backgroundColor: selectedIndex === index ? "primary.dark" : "",
                                            ":hover": {
                                                backgroundColor: selectedIndex === index ? "primary.dark" : "",
                                            }
                                        }}
                                        onClick={() => {
                                            handleNavbarNavigation(entryObj.name, entryObj.to, index);
                                        }}
                                    >
                                        <ListItemIcon>
                                            {entryObj.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={entryObj.name}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                    <ProjectVersion/>
                </Box>
            </IconContext.Provider>
        </Drawer>
    );
};

export default NavBar;
